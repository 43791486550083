import React from 'react';
import { RecipeTabContentTitle } from '@/app/menus/[menuId]/components/recipe-card/tabs/RecipeTabContentTitle';
import { RecipeWithPricing } from '@/models/Recipe';
import { NutritionalFacts, NutritionalFactsTable } from '@/components/NutritionalFactsTable';

export type NutritionalContentsProps = { recipe: RecipeWithPricing };

export function NutritionalContents({}: NutritionalContentsProps) {
    const mockFacts: NutritionalFacts = {
        calories_per_serving: 340,
        serving_size: '1/3 cup (27g)',
        servings_per_container: 5,
        breakdown: [
            {
                name: 'Total Fat',
                amount: 47,
                units: 'g',
                pct_daily_value: 0.67,
                items: [
                    { name: 'Saturated Fat', amount: 19, units: 'g', pct_daily_value: 0.45 },
                    { name: 'Trans Fat', amount: 8, units: 'g', pct_daily_value: 0.34 },
                ],
            },
            { name: 'Cholesterol', amount: 2, units: 'g', pct_daily_value: 0.45 },
            { name: 'Sodium', amount: 7, units: 'g', pct_daily_value: 0.18 },
            {
                name: 'Total Carbohydrate',
                amount: 3,
                units: 'g',
                pct_daily_value: 0.08,
                items: [
                    { name: 'Dietary Fiber', amount: 0, units: 'g', pct_daily_value: 0.0 },
                    {
                        name: 'Total sugars',
                        amount: 0,
                        units: 'g',
                        pct_daily_value: 0.0,
                        items: [{ name: 'Added Sugars', amount: 0, units: 'g', pct_daily_value: 0.0 }],
                    },
                ],
            },
            { name: 'Protein', amount: 22, units: 'g', pct_daily_value: 0.47 },
        ],
    };
    return (
        <div>
            <RecipeTabContentTitle>Nutrition Facts</RecipeTabContentTitle>
            <div className={'max-w-md'}>
                <NutritionalFactsTable facts={mockFacts} />
            </div>
        </div>
    );
}

NutritionalContents.displayName = 'NutritionalContents';
