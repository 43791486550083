import React from 'react';
import { cn } from '@/util/styleUtils';
import { Separator } from '@/components/ui/separator';
import { isDefined } from '@/util/TypeGuards';
import { pluralize } from '@/util/StringUtil';

export type NutritionalItem = { name: string; amount: number; units: string; pct_daily_value: number };
type NutritionalItemWithItems = NutritionalItem & { items?: NutritionalItem[] };
export type NutritionalHeadingItem = NutritionalItem & { items?: NutritionalItemWithItems[] };
export type NutritionalFacts = {
    breakdown: NutritionalHeadingItem[];
    calories_per_serving: number;
    serving_size?: string;
    servings_per_container?: number;
};

export type NutritionalFactsTableProps = { facts: NutritionalFacts };

const Row = ({ item, depth = 0 }: { item: NutritionalItemWithItems; depth?: number }) => {
    return (
        <>
            <tr className={'text-sm'}>
                <td>
                    <span
                        className={cn('mr-2', { 'font-bold': depth === 0, 'ml-4': depth === 1, 'ml-8': depth === 2 })}
                    >
                        {item.name}
                    </span>
                    <span>
                        {item.amount}
                        {item.units}
                    </span>
                </td>
                <td align={'right'}>{Math.round(item.pct_daily_value * 100)}%</td>
            </tr>
            {item.items?.map((subItem, i) => <Row item={subItem} depth={depth + 1} key={`sub_${i}`} />)}
        </>
    );
};

export function NutritionalFactsTable({ facts }: NutritionalFactsTableProps) {
    const hasServingSize = isDefined(facts.servings_per_container) || isDefined(facts.serving_size);
    return (
        <div className={'rounded-md border p-2'}>
            {isDefined(facts.servings_per_container) && (
                <p>{pluralize(facts.servings_per_container, 'servings', 'serving')} per container</p>
            )}
            {isDefined(facts.serving_size) && (
                <div className={'flex justify-between font-bold'}>
                    <span>Serving size</span>
                    <span>{facts.serving_size}</span>
                </div>
            )}
            {hasServingSize && <Separator className={'my-2 h-2 bg-foreground'} />}
            <div>
                <p>Amount per serving</p>
                <div className={'flex justify-between text-lg font-bold'}>
                    <span>Calories</span>
                    <span>{facts.calories_per_serving}</span>
                </div>
            </div>
            <Separator className={'my-2 h-1 bg-foreground'} />
            <table className={'table w-full'}>
                <thead>
                    <tr>
                        <th />
                        <th align={'right'}>% Daily Value</th>
                    </tr>
                </thead>
                <tbody className={'divide-y'}>
                    {facts.breakdown.map((fact, i) => (
                        <Row item={fact} key={i} />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

NutritionalFactsTable.displayName = 'NutritionalFactsTable';
